import {notification, message} from "antd";
import React from "react";

export const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};
/*export const tailLayout = {
    wrapperCol: {
        offset: 2,
        span: 16,
    },
};*/

export const version = () => {
   return '2.6.11'
};

export const toSentenceCase = (str) => {
    if (!str) return str;

    return str.replace(/(^|\. *)([a-z])/g, (match, separator, char) => {
        return separator + char.toUpperCase();
    });
}

export const successN = (str) => {
    message.success(str);
  };

  export const errorN = (str) => {
    message.error(str);
  };

  export const warningN = (str) => {
    message.warning(str);
  };

export const openNotificationError = (placement,str) => {
    const key = `open${Date.now()}`;

    notification.error({
        message: `Failed!`,
        description:
        str,
        placement,
        duration: 5,
        key
    });
};

export const openNotificationSuccess = (placement,str) => {
    const key = `open${Date.now()}`;


    notification.success({
        message: `Success!`,
        description:
        str,
        placement,
        duration: 5,
        key
    });
};

/*export const openNotificationInfo = (placement,str) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
            Close
        </Button>
    );

    notification.info({
        message: `Info!`,
        description:
        str,
        placement,
        duration: 0,
        btn,
        key,
        onClose: close,
    });
};

export const openNotificationWarning = (placement,str) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
            Close
        </Button>
    );

    notification.warn({
        message: `Warning!`,
        description:
        str,
        placement,
        duration: 0,
        btn,
        key,
        onClose: close,
    });
};*/

export const CountryList = () => {
    return (
        [
            {"Code":"AD","Country":"Andorra","ISO":53,"Nationality":"Andorian"},
            {"Code":"AE","Country":"United Arab Emirates","ISO":126,"Nationality":"Emirian"},
            {"Code":"AF","Country":"Afghanistan","ISO":133,"Nationality":"Afghani"},
            {"Code":"AI","Country":"Anguilla","ISO":55,"Nationality":"Anguillan"},
            {"Code":"AM","Country":"Armenia","ISO":58,"Nationality":"Armenian"},
            {"Code":"AO","Country":"Angola","ISO":54,"Nationality":"Angolian"},
            {"Code":"AQ","Country":"Antarctica","ISO":55,"Nationality":"Antarctic"},
            {"Code":"AR","Country":"Argentina","ISO":57,"Nationality":"Argentine"},
            {"Code":"AS","Country":"Austria","ISO":3,"Nationality":"Austrian"},
            {"Code":"AU","Country":"Australia","ISO":2,"Nationality":"Australian"},
            {"Code":"AW","Country":"Aruba","ISO":59,"Nationality":"Arubian"},
            {"Code":"BA","Country":"Bangladesh","ISO":45,"Nationality":"Bangladeshi"},
            {"Code":"BB","Country":"Barbados","ISO":63,"Nationality":"Barbadian"},
            {"Code":"BE","Country":"Belgium","ISO":4,"Nationality":"Belgian"},
            {"Code":"BH","Country":"Bahrain","ISO":62,"Nationality":"Bahrainian"},
            {"Code":"BM","Country":"Bermuda","ISO":67,"Nationality":"Bermuda"},
            {"Code":"BO","Country":"Bolivia","ISO":69,"Nationality":"Bolivian"},
            {"Code":"BR","Country":"Brazil","ISO":43,"Nationality":"Brazilian"},
            {"Code":"BS","Country":"Bahamas","ISO":61,"Nationality":"Bahameese"},
            {"Code":"BT","Country":"Bhutan","ISO":68,"Nationality":"Bhutanese"},
            {"Code":"BU","Country":"Bulgaria","ISO":44,"Nationality":"Bulgarian"},
            {"Code":"BY","Country":"Belarus","ISO":64,"Nationality":"Belarusian"},
            {"Code":"BZ","Country":"Belize","ISO":65,"Nationality":"Belizean"},
            {"Code":"CA","Country":"Canada","ISO":6,"Nationality":"Canadian"},
            {"Code":"CG","Country":"Congo","ISO":72,"Nationality":"Congolese"},
            {"Code":"CH","Country":"China","ISO":51,"Nationality":"Chinese"},
            {"Code":"CH","Country":"Switzerland","ISO":35,"Nationality":"Swiss"},
            {"Code":"CL","Country":"Chile","ISO":71,"Nationality":"Chilean"},
            {"Code":"CM","Country":"Cambodia","ISO":5,"Nationality":"Cambodian"},
            {"Code":"CM","Country":"Cameroon","ISO":70,"Nationality":"Cameroonian"},
            {"Code":"CO","Country":"Columbia","ISO":46,"Nationality":"Columbian"},
            {"Code":"CR","Country":"Czech Republic","ISO":50,"Nationality":"Czech"},
            {"Code":"CR","Country":"Costa Rica","ISO":73,"Nationality":"Costa Rican"},
            {"Code":"CU","Country":"Cuba","ISO":75,"Nationality":"Cuban"},
            {"Code":"CY","Country":"Cyprus","ISO":76,"Nationality":"Cypriot"},
            {"Code":"DE","Country":"Germany","ISO":10,"Nationality":"German"},
            {"Code":"DK","Country":"Denmark","ISO":7,"Nationality":"Danish"},
            {"Code":"DM","Country":"Dominica","ISO":77,"Nationality":"Dominican"},
            {"Code":"EC","Country":"Ecuador","ISO":78,"Nationality":"Ecuadorean"},
            {"Code":"EE","Country":"Estonia","ISO":79,"Nationality":"Estonian"},
            {"Code":"EG","Country":"Egypt","ISO":8,"Nationality":"Egyptian"},
            {"Code":"ET","Country":"Ethiopia","ISO":80,"Nationality":"Ethiopian"},
            {"Code":"FI","Country":"Finland","ISO":82,"Nationality":"Finnish"},
            {"Code":"FJ","Country":"Fiji","ISO":81,"Nationality":"Fijian"},
            {"Code":"FR","Country":"France","ISO":9,"Nationality":"French"},
            {"Code":"GB","Country":"United Kingdom","ISO":39,"Nationality":"British"},
            {"Code":"GE","Country":"Georgia","ISO":83,"Nationality":"Georgian"},
            {"Code":"GH","Country":"Ghana","ISO":84,"Nationality":"Ghanaian"},
            {"Code":"GN","Country":"Guinea","ISO":86,"Nationality":"Guinean"},
            {"Code":"GR","Country":"Greece","ISO":11,"Nationality":"Greek"},
            {"Code":"GY","Country":"Guyana","ISO":87,"Nationality":"Guyanese"},
            {"Code":"HK","Country":"Hong Kong","ISO":13,"Nationality":"Chinese"},
            {"Code":"HR","Country":"Croatia","ISO":74,"Nationality":"Croatian"},
            {"Code":"HU","Country":"Hungary","ISO":14,"Nationality":"Hungarian"},
            {"Code":"ID","Country":"Indonesia","ISO":16,"Nationality":"Indonesian"},
            {"Code":"IE","Country":"Ireland","ISO":90,"Nationality":"Irish"},
            {"Code":"IN","Country":"India","ISO":15,"Nationality":"Indian"},
            {"Code":"IQ","Country":"Iraq","ISO":89,"Nationality":"Iraqi"},
            {"Code":"IR","Country":"Iran","ISO":17,"Nationality":"Iranian"},
            {"Code":"IS","Country":"Israel","ISO":18,"Nationality":"Israeli"},
            {"Code":"IS","Country":"Iceland","ISO":88,"Nationality":"Icelander"},
            {"Code":"IT","Country":"Italy","ISO":19,"Nationality":"Italian"},
            {"Code":"JM","Country":"Jamaica","ISO":91,"Nationality":"Jamaican"},
            {"Code":"JO","Country":"Jordan","ISO":92,"Nationality":"Jordanian"},
            {"Code":"JP","Country":"Japan","ISO":20,"Nationality":"Japanese"},
            {"Code":"KE","Country":"Kenya","ISO":94,"Nationality":"Kenyan"},
            {"Code":"KO","Country":"Korea","ISO":21,"Nationality":"Korean"},
            {"Code":"KW","Country":"Kuwait","ISO":95,"Nationality":"Kuwaiti"},
            {"Code":"KZ","Country":"Kazakhstan","ISO":134,"Nationality":"Kazakhstani"},
            {"Code":"KZ","Country":"Kazakhstan","ISO":93,"Nationality":"Kazakhstani"},
            {"Code":"LB","Country":"Lebanon","ISO":96,"Nationality":"Lebanese"},
            {"Code":"LK","Country":"Sri Lanka","ISO":33,"Nationality":"Sri Lankan"},
            {"Code":"LT","Country":"Lithuania","ISO":97,"Nationality":"Lithuanian"},
            {"Code":"LU","Country":"Luxembourg","ISO":98,"Nationality":"Luxembourger"},
            {"Code":"MA","Country":"Morocco","ISO":104,"Nationality":"Moroccan"},
            {"Code":"MC","Country":"Monaco","ISO":102,"Nationality":"Monacan"},
            {"Code":"ME","Country":"Mexico","ISO":47,"Nationality":"Mexican"},
            {"Code":"MM","Country":"Myanmar","ISO":105,"Nationality":"Mayanmarese"},
            {"Code":"MN","Country":"Mongolia","ISO":103,"Nationality":"Mongolian"},
            {"Code":"MO","Country":"Macau","ISO":42,"Nationality":"Macau"},
            {"Code":"MU","Country":"Mauritius","ISO":100,"Nationality":"Mauritian"},
            {"Code":"MV","Country":"Maldives","ISO":99,"Nationality":"Maldivan"},
            {"Code":"MY","Country":"Malaysia","ISO":22,"Nationality":"Malaysian"},
            {"Code":"NA","Country":"Namibia","ISO":106,"Nationality":"Namibian"},
            {"Code":"NG","Country":"Nigeria","ISO":108,"Nationality":"Nigerian"},
            {"Code":"NL","Country":"Netherland","ISO":12,"Nationality":"Dutch"},
            {"Code":"NO","Country":"Norway","ISO":24,"Nationality":"Norwegian"},
            {"Code":"NP","Country":"Nepal","ISO":107,"Nationality":"Nepalese"},
            {"Code":"NZ","Country":"New Zealand","ISO":23,"Nationality":"New Zealander"},
            {"Code":"OM","Country":"Oman","ISO":109,"Nationality":"Omani"},
            {"Code":"PA","Country":"Panama","ISO":110,"Nationality":"Panamanian"},
            {"Code":"PE","Country":"Peru","ISO":112,"Nationality":"Peruvian"},
            {"Code":"PH","Country":"Philippines","ISO":27,"Nationality":"Filipino"},
            {"Code":"PK","Country":"Pakistan","ISO":26,"Nationality":"Pakistani"},
            {"Code":"PO","Country":"Poland","ISO":28,"Nationality":"Polish"},
            {"Code":"PT","Country":"Portugal","ISO":113,"Nationality":"Portugees"},
            {"Code":"PY","Country":"Paraguay","ISO":111,"Nationality":"Paraguayan"},
            {"Code":"QA","Country":"Qatar","ISO":115,"Nationality":"Qatari"},
            {"Code":"RO","Country":"Romania","ISO":48,"Nationality":"Romanian"},
            {"Code":"RU","Country":"Russia","ISO":29,"Nationality":"Russian"},
            {"Code":"SA","Country":"Saudi Arabia","ISO":116,"Nationality":"Saudi Arabian"},
            {"Code":"SC","Country":"Seychelles","ISO":119,"Nationality":"Seychellois"},
            {"Code":"SE","Country":"Sweden","ISO":34,"Nationality":"Swedish"},
            {"Code":"SG","Country":"Singapore","ISO":30,"Nationality":"Singaporean"},
            {"Code":"SK","Country":"Slovakia","ISO":120,"Nationality":"Slovakian"},
            {"Code":"SN","Country":"Senegal","ISO":117,"Nationality":"Senegalese"},
            {"Code":"SO","Country":"Somalia","ISO":121,"Nationality":"Somali"},
            {"Code":"SP","Country":"Spain","ISO":32,"Nationality":"Spanish"},
            {"Code":"TH","Country":"Thailand","ISO":37,"Nationality":"Thai"},
            {"Code":"TN","Country":"Tunisia","ISO":123,"Nationality":"Tunisian"},
            {"Code":"TR","Country":"Turkey","ISO":38,"Nationality":"Turkish"},
            {"Code":"TW","Country":"Taiwan","ISO":36,"Nationality":"Taiwanese"},
            {"Code":"TZ","Country":"Tanzania","ISO":122,"Nationality":"Tanzanian"},
            {"Code":"UA","Country":"Ukraine","ISO":125,"Nationality":"Ukrainian"},
            {"Code":"UG","Country":"Uganda","ISO":124,"Nationality":"Ugandan"},
            {"Code":"US","Country":"United States of America","ISO":40,"Nationality":"American"},
            {"Code":"UY","Country":"Uruguay","ISO":127,"Nationality":"Uruguayan"},
            {"Code":"UZ","Country":"Uzbekistan","ISO":128,"Nationality":"Uzbekistani"},
            {"Code":"VE","Country":"Venezuela","ISO":49,"Nationality":"Venezuelan"},
            {"Code":"VN","Country":"Vietnam","ISO":1,"Nationality":"Vietnamese"},
            {"Code":"YE","Country":"Yemen","ISO":130,"Nationality":"Yemeni"},
            {"Code":"ZA","Country":"South Africa","ISO":31,"Nationality":"South African"},
            {"Code":"ZM","Country":"Zambia","ISO":131,"Nationality":"Zambian"},
            {"Code":"ZW","Country":"Zimbabwe","ISO":132,"Nationality":"Zimbabwean"}
        ]
    )}


